import { FC, useState, ReactNode, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { LinearProgress } from '@mui/material';

interface IWaitForUserProps {
  children: ReactNode;
}

export const WaitForUser: FC<IWaitForUserProps> = (props) => {
  const { children } = props;
  const [auth, setAuth] = useState();
  const [loadedAuth, setLoadedAuth] = useState(true);

  useEffect(() => {
    axios
      .get('/api/auth/me')
      .then((res) => {
        setAuth(res.data);
      })
      .catch(() => {
        console.log('err');
      })
      .finally(() => {
        setLoadedAuth(false);
      });
  }, []);

  if (loadedAuth) {
    return <LinearProgress />;
  }

  if (!auth) {
    return <Redirect to="/auth/login" />;
  }

  return <>{children}</>;
};
