import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { LinkProps } from '@mui/material/Link';
import { LinkBehavior } from './components/LinkBehavior';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  },
});
