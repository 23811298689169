import { lazy } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { WaitForUser } from './components/layauts/WaitForUser';

const Dashboard = lazy(() => import('./modules/dashboard'));
const Vpn = lazy(() => import('./modules/vpn'));
const Auth = lazy(() => import('./modules/auth'));
const Chroma = lazy(() => import('./modules/chroma'));

function App() {
  return (
    <div className="content">
      <Router>
        {/* modules */}
        <WaitForUser>
          <Dashboard />
          <Vpn />
          <Chroma />
        </WaitForUser>
        <Auth />
        {/* modules end */}
      </Router>
    </div>
  );
}

export default App;
